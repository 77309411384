import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { withStyles } from "@material-ui/core/styles";
import QAudioRecorder from "./QAudioRecorder";

const styles = (theme) => ({
    root: {
        flexGrow: 1
    },
    paper: {
        height: 140,
        width: 100
    },
    control: {
        padding: theme.spacing(2)
    },
    formControl: {
        margin: theme.spacing(1),
        width: 200
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    },
    label: {
        backgroundColor: "white"
    }

});


class SentenceBar extends Component {
    constructor(props) {

        super(props);
        this.state = {
            blobAudioURL: "",
            blobAudio: {},
            showSubmitAudio: false,
            senstecesArray: [],
        };
    }
    getAudio = async (data) => {
        this.setState({
            blobAudioURL: data.blobAudioURL,
            showSubmitAudio: data.showSubmitAudio,
            blobAudio: data.blobAudio
        });
    };
    componentDidMount =  () => {
        this.setState({ senstecesArray: this.props.listSentences });
    };
    
    UNSAFE_componentWillUpdate = (prevState, prevProps) => {
        if (prevState.listSentences !== this.state.senstecesArray) {
            (this.setState({ senstecesArray: this.props.listSentences }));
        }
    };
    render() {
        const { classes } = this.props;
        return (
            <div>
                { this.state.senstecesArray.map((sentence,i) => (

                <div key={sentence.sentenceNo}>
                <Grid container
                    className={
                        classes.root
                    }>
                    <Grid item
                        xs={4}>
                        <Typography variant="h5">
                           {sentence.sentenceContent}
                        </Typography>
                    </Grid>
                    <Grid item
                        xs={4}>
                        <QAudioRecorder type='mp3' onAudioRecorded={
                            this.getAudio
                        }
                            recordedAudio={
                                this.state.blobAudioURL
                            } />
                    </Grid>
                </Grid>
                <Divider variant="middle" />
                </div>))
                }
            </div>
        );
    }
}

export default withStyles(styles)(SentenceBar);
