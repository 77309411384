import React, { Component } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Divider from '@material-ui/core/Divider';
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Axios from "axios";
import SentenceBar from "./components/SentenceBar";

const styles = (theme) => ({
  root: {
    flexGrow: 1
  },
  paper: {
    height: 140,
    width: 100
  },
  control: {
    padding: theme.spacing(2)
  },
  formControl: {
    margin: theme.spacing(1),
    width: 200
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  label: {
    backgroundColor: "white"
  }
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selection1: "",
      selection2: "",
      showSelect2: false,
      showSentencesPanel: false,
      allModues: [],
      allModuleUnits: [],
      allUnitContent: []
    };
  }
  getAudio = async (data) => {
    this.setState({ blobAudioURL: data.blobAudioURL, showSubmitAudio: data.showSubmitAudio, blobAudio: data.blobAudio });
  };

  componentDidMount = async () => {
    let userData = await Axios.get("./data/arabicSentences.json");
    this.setState({ allModues: userData.data.modules });
    console.log(userData.data);
  };
  handleChange1 = (moduleContent) => {
    this.setState({
      selection1: moduleContent.target.value,
      showSelect2: true,
      showSentencesPanel: false,
      allModuleUnits: moduleContent.target.value,
      allUnitContent: [],
      selection2: "",
    });
  };
  handleChange2 = (unitContent) => {
    this.setState({
      selection2: unitContent.target.value,
      allUnitContent: unitContent.target.value,
      showSentencesPanel: true
    });
  };
  render() {
    const { classes } = this.props;
    const { showSelect2, selection1, selection2, showSentencesPanel } = this.state;
    return (
      <React.Fragment>
        <CssBaseline />
        <Container maxWidth="lg" justify="center">
          <Grid container
            className={
              classes.root
            }>
            <Grid item
              xs={12}>
              <h1 style={
                { textAlign: "center" }
              }>Qasid Pronunciation Tutor</h1>
            </Grid>
          </Grid>
          <Grid container
            className={
              classes.root
            }>
            <Grid item
              xs={4}>
              <FormControl variant="outlined"
                className={
                  classes.formControl
                }>
                <InputLabel id="demo-simple-select-outlined-label">
                  Module
                                </InputLabel>
                <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined"
                  value={selection1}
                  onChange={
                    (moduleContent) => this.handleChange1(moduleContent)
                  }
                  label="module">
                  {
                    this.state.allModues.map((mod, i) => (
                      <MenuItem key={i}
                        value={
                          mod.moduleUnits
                        }>
                        {
                          mod.moduleName
                        }</MenuItem>
                    ))
                  } </Select>
              </FormControl>
            </Grid>
            {
              showSelect2 === true && (
                <Grid item
                  xs={4}>
                  <FormControl variant="outlined"
                    className={
                      classes.formControl
                    }>
                    <InputLabel id="">Unit</InputLabel>
                    <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined"
                      value={selection2}
                      onChange={
                        (unitContent) => this.handleChange2(unitContent)
                      }
                      label="units">
                      {
                        this.state.allModuleUnits.map((unit, i) => (
                          <MenuItem key={
                            i + 100
                          }
                            value={
                              unit.unitSentences
                            }>
                            {
                              unit.unitName
                            }</MenuItem>
                        ))
                      } </Select>
                  </FormControl>
                </Grid>
              )
            } </Grid>
          { showSentencesPanel === true &&
            <div>
              <Divider variant="middle" />
              <SentenceBar listSentences={this.state.allUnitContent} />
            </div>
          }
        </Container>


      </React.Fragment>
    );
  }
}

export default withStyles(styles)(App);
